import React, { useContext, useEffect, useRef, useState } from 'react';
import { LanguageContext } from '../../context/language.context';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import toast from 'react-hot-toast';
import { GoArrowLeft } from "react-icons/go";
import MDEditor, { commands } from "@uiw/react-md-editor";
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import Loading from '../../components/Loading/Loading';

const LessonAdminPage = () => {
    const { strings } = useContext(LanguageContext);
    const { lessonId } = useParams();
    const lessonNameRef = useRef('');
    const lessonSlugRef = useRef('');
    const [lessonContent, setLessonContent] = useState('');
    const editorContentRef = useRef('');
    const [lesson, setLesson] = React.useState('')
    const navigate = useNavigate()
    const [course, setCourse] = useState('')

    useEffect(() => {
        const fetchLesson = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/lesson/getLesson/${lessonId}`, {
                    headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
                });
                lessonNameRef.current = response.data.name;
                lessonSlugRef.current = response.data.slug;
                setLessonContent(response.data.content);
                setLesson(response.data);
                setCourse(response.data.course)
                console.log(response.data.module)
                editorContentRef.current = response.data.content;
            } catch (error) {
                console.error('Error fetching lesson:', error);
            }
        }

        fetchLesson();
    }, [lessonId]);

    const handleNameChange = (e) => {
        lessonNameRef.current = e.target.value;
    };

    const handleSlugChange = (e) => {
        lessonSlugRef.current = e.target.value;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (lessonNameRef.current === '') {
            toast.error('Lesson name cannot be empty');
            return;
        }

        if (lessonSlugRef.current === '') {
            toast.error('Lesson slug cannot be empty');
            return;
        }

        // Step 1: Parse the lessonContent string into a DOM
        const parser = new DOMParser();
        const doc = parser.parseFromString(lessonContent, 'text/html');

        // Step 2: Find and modify all <video> elements
        const videos = doc.getElementsByTagName('video');
        Array.from(videos).forEach(video => {
            video.setAttribute('controlsList', 'nodownload');
            video.setAttribute('oncontextmenu', 'return false;');
        });

        // Step 3: Serialize the modified DOM back to a string
        const updatedContent = doc.body.innerHTML;

        // Prepare the updated lesson data
        const updatedLesson = {
            name: lessonNameRef.current,
            slug: lessonSlugRef.current,
            content: updatedContent
        };

        // Step 4: Submit the updated lesson using axios
        toast.promise(
            axios.patch(`${process.env.REACT_APP_SERVER_URL}/lesson/updateLesson/${lessonId}`, updatedLesson, {
                headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
            }),
            {
                loading: <b>{strings.toast.toastLoading}</b>,
                success: <b>{strings.toast.toastSuccess}</b>,
                error: <b>{strings.toast.toastError}</b>,
            }
        );
    };

    if (!course) {
        return <Loading />;
    }

    const handlePreview = () => {
        navigate(`/lesson-preview-admin/${course._id}/${lessonId}`);
    };

    const insertLoomVideoCommand = {
        name: "insertLoom",
        keyCommand: "insertLoom",
        buttonProps: { "aria-label": "Insert Loom Video" },
        icon: (
            <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 576 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M336.2 64H47.8C21.4 64 0 85.4 0 111.8v288.4C0 426.6 21.4 448 47.8 448h288.4c26.4 0 47.8-21.4 47.8-47.8V111.8c0-26.4-21.4-47.8-47.8-47.8zm189.4 37.7L416 177.3v157.4l109.6 75.5c21.2 14.6 50.4-.3 50.4-25.8V127.5c0-25.4-29.1-40.4-50.4-25.8z"></path></svg>
        ),
        execute: (state, api) => {
            const url = prompt('Enter Loom video URL:');
            if (url) {
                // Validate Loom URL
                const loomPattern = /^https?:\/\/(www\.)?loom\.com\/share\/([A-Za-z0-9]+)/;
                const match = url.match(loomPattern);
                if (match) {
                    const embedUrl = `https://loom.com/embed/${match[2]}`;
                    const iframe = `<div style="position:relative;padding-top:56.25%;"><iframe src="${embedUrl}" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe></div>`;
                    api.replaceSelection(iframe);
                } else {
                    toast.error('Please enter a valid Loom share URL.');
                }
            }
        },
    };

    return (
        <div className='mb-20 lg:py-8 containerNavbar'>
            <Breadcrumbs courseName={course?.title} courseId={course?._id} lessonName={lesson?.name} moduleName={lesson?.module?.name} moduleId={lesson?.module?._id} />
            <div className="fixed hidden p-2 bg-white rounded-full lg:block left-[3%] 2xl:left-[6%] cursor-pointer top-32 z-[100]" onClick={() => navigate(`/courses-admin/${course._id}?view=modules&module=${lesson?.module._id}`)}>
                <GoArrowLeft className="text-black size-6" />
            </div>
            <form onSubmit={handleSubmit} className="space-y-8">
                <div className='flex flex-row items-center justify-between'>
                    <div className='flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-2 w-full md:w-1/2 lg:w-3/5'>
                        <div className='flex flex-col w-full md:w-1/2'>
                            <label className="block mb-2 text-xs font-bold tracking-wide text-white uppercase">Lesson Name</label>
                            <input
                                type="text"
                                defaultValue={lessonNameRef.current}
                                placeholder="Enter lesson name"
                                onChange={handleNameChange}
                                className="w-full px-4 h-12 text-xs font-normal tracking-[1px] border border-[#6753B41A] rounded outline-none bg-[#CFC4F814] text-secondary placeholder-inherit"
                            />
                        </div>
                        <div className='flex flex-col w-full md:w-1/2'>
                            <label className="block mb-2 text-xs font-bold tracking-wide text-white uppercase">Lesson Slug</label>
                            <input
                                type="text"
                                defaultValue={lessonSlugRef.current}
                                placeholder="Enter lesson slug"
                                onChange={handleSlugChange}
                                className="w-full px-4 h-12 text-xs font-normal tracking-[1px] border border-[#6753B41A] rounded outline-none bg-[#CFC4F814] text-secondary placeholder-inherit"
                            />
                        </div>
                    </div>
                    <div className='flex self-end ml-2 space-x-4'>
                        <button type="button" onClick={handlePreview} className="self-end hidden md:flex hover:bg-primary-hover rounded transition duration-500 ease-in-out bg-primary text-[11px] sm:text-[12px] uppercase font-bold h-12 py-4 px-6 text-white hover:text-textHover tracking-[2px]">
                            Preview
                        </button>
                        <button type="submit" className="self-end flex-shrink-0 hidden md:flex hover:bg-primary-hover rounded transition duration-500 ease-in-out bg-primary text-[11px] sm:text-[12px] uppercase font-bold h-12 py-4 px-6 text-white hover:text-textHover tracking-[2px]">
                            Save Changes
                        </button>
                    </div>
                </div>
                <div>
                    <label className="block mb-2 text-xs font-bold tracking-wide text-white uppercase">Lesson Content</label>
                    <MDEditor
                        value={lessonContent}
                        height="100vh"
                        commands={[...commands.getCommands(), commands.divider, insertLoomVideoCommand]}
                        onChange={setLessonContent}
                    />
                </div>

                <button type="submit" className="flex md:hidden hover:bg-primary-hover rounded transition duration-500 ease-in-out bg-primary text-[11px] sm:text-[12px] uppercase font-bold py-3 px-6 text-white hover:text-textHover tracking-[2px] float-end">
                    Save Changes
                </button>
            </form>
        </div>
    );
};

export default LessonAdminPage;
