import { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from '../../context/auth.context';
import { LanguageContext } from "../../context/language.context";
import toast from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router-dom';
import { CartContext } from '../../context/cart.context';
import emailjs from 'emailjs-com';
import { useStripe } from '@stripe/react-stripe-js';

function Completion(props) {
    const { user } = useContext(AuthContext);
    const { strings } = useContext(LanguageContext);
    const location = useLocation();
    const navigate = useNavigate();
    const { handleCartClearAndRefresh } = useContext(CartContext)
    const [purchaseCompleted, setPurchaseCompleted] = useState(false)


    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const paymentIntentId = queryParams.get('payment_intent');
        if (!paymentIntentId) {
            toast.error('Payment verification failed.');
            return;
        }

        const fetchPaymentIntentAndPurchaseCourse = async () => {
            try {
                const response = await axios.patch(
                    `${process.env.REACT_APP_SERVER_URL}/stripe/verify-and-purchase`,
                    {
                        paymentIntentId,
                        userId: user._id,
                    },
                    {
                        headers: { 'Authorization': `Bearer ${localStorage.getItem('accessToken')}` }
                    }
                );
                const formData = {
                    fullname: user?.firstName + user?.lastName,
                    email: user?.email,
                    message: 'Este user quer uma instalação de wordpress.',
                }

                if (response.data.checkedWordpress === "true") {
                    emailjs.send('service_9anr04k', 'template_ec2apsw', formData, 'NHpSHmXVdTR52k5HN')
                        // emailjs.send('service_sm0bjg7', 'template_3qpis8z', formData, 'twRuWq-X1NkN-PrOz') testing for ruben
                        .then((response) => {
                            console.log('Email sent successfully');
                        }, (error) => {
                            console.error('Failed to send email:', error);
                        });
                }

                if (response.data.success) {
                    toast.success('Course successfully purchased.');
                    setPurchaseCompleted(!purchaseCompleted)
                } else {
                    toast.error(response.data.message || 'Payment verification failed.');
                }
            } catch (error) {
                console.error("Error during payment verification and course purchase:", error);
                toast.error('Internal error during payment verification.');
            }
        };

        fetchPaymentIntentAndPurchaseCourse();
    }, [user._id, location.search]);
    // now using location.search which is a string and more stable

    // Example: Conditional fetching in CartProviderWrapper
    useEffect(() => {
        if (purchaseCompleted) {
            handleCartClearAndRefresh()
        }
    }, [purchaseCompleted]);


    return (
        <div className="w-3/4 py-8 mx-auto containerNavbar">
            <div className="flex items-center justify-center mt-12 mb-12">
                <h1 className="text-2xl font-bold text-white sm:text-4xl">{strings.purchase.thanks}<span className="ml-1 text-2xl font-bold text-primary sm:text-4xl">!</span></h1>
            </div>
            <div className='flex justify-center'>
                <button
                    onClick={() => navigate("/mycourses")}
                    className="hover:bg-primary-hover rounded transition duration-500 ease-in-out bg-primary text-[11px] sm:text-[12px] uppercase font-bold py-3 px-6 text-white hover:text-textHover tracking-[2px]"
                >
                    Ir para cursos comprados
                </button>
            </div>
        </div>
    );
}

export default Completion;