import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";
import { AuthContext } from '../context/auth.context'; // Adjust the path as necessary
import toast from "react-hot-toast";

const CartContext = createContext();

// CREATE A WRAPPER COMPONENT
function CartProviderWrapper(props) {
    const [cart, setCart] = useState([]);
    const { user } = useContext(AuthContext);  // Access authentication context
    const [coupon, setCoupon] = useState("");
    const [couponInfo, setCouponInfo] = useState("");
    const [couponApplied, setCouponApplied] = useState(false);
    const [amount, setAmount] = useState("");
    const [checkedWordpress, setCheckedWordpress] = useState(false)

    // State to track which WordPress items have the extra €10 added
    const [wordpressSelections, setWordpressSelections] = useState({});

    const getUserCart = async () => {
        if (!user) {
            setCart([])
            return;
        }
        try {
            const res = await axios.get(
                `${process.env.REACT_APP_SERVER_URL}/user/cart/${user._id}`,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
                    },
                }
            );
            setCart(res.data)
        } catch (error) {
            console.error("Error fetching user cart", error);
        }
    };

    useEffect(() => {
        getUserCart();
    }, [user]);

    const handleAddItemCart = async (course) => {
        const updatedCart = [...cart, course];  // Create the updated cart array

        // console.log(updatedCart)

        // Update the local state with the new cart value
        setCart(updatedCart);
        const payload = {
            course: course._id
        }

        const type = "addItem"

        try {
            const res = await axios.patch(
                `${process.env.REACT_APP_SERVER_URL}/user/cart/${user._id}`,
                { payload, type },  // Send the updated cart in the request body
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
                    },
                }
            );
            if (res) toast.success("Curso Adicionado ao Carrinho.");
        } catch (error) {
            console.error("Failed to update cart", error);
        }
    };


    const handleRemoveItemCart = async (course) => {
        // Update the cart state using a callback function that filters out the course
        setCart(prevCart => {
            const updatedCart = prevCart.filter(item => item._id !== course._id);

            // (Optional) You could log the updated cart here, but note that this log won't trigger a re-render.
            // console.log(updatedCart);

            return updatedCart;
        });

        const payload = { course: course._id };
        const type = "removeItem";

        try {
            const res = await axios.patch(
                `${process.env.REACT_APP_SERVER_URL}/user/cart/${user._id}`,
                { payload, type },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
                    },
                }
            );
            if (res) toast.success("Curso Removido do Carrinho.");

            // Optionally, update the cart with the server response if needed:
            // if (res.data && res.data.cart) {
            //   setCart(res.data.cart);
            // }
        } catch (error) {
            console.error("Failed to update cart", error);
        }
    };


    const handleRemoveAllItemCart = async () => {
        try {
            const res = await axios.delete(
                `${process.env.REACT_APP_SERVER_URL}/user/cart/${user._id}`,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
                    },
                }
            );
        } catch (error) {
            console.error("Failed to update cart", error);
        }
    }

    const handleCartClearAndRefresh = async () => {
        await handleRemoveAllItemCart();
        // Optionally, you can wait a moment here if needed
        setCart([]);
        setCoupon("");
        setCouponInfo("");
        setCouponApplied(false);
        // Then refetch or not, depending on your backend logic
    };


    return (
        <CartContext.Provider value={{ cart, setCart, handleAddItemCart, handleRemoveItemCart, coupon, setCoupon, couponInfo, setCouponInfo, couponApplied, setCouponApplied, amount, setAmount, wordpressSelections, setWordpressSelections, checkedWordpress, setCheckedWordpress, handleCartClearAndRefresh }}>
            {props.children}
        </CartContext.Provider>
    );
}

export { CartContext, CartProviderWrapper };