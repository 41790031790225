import "./Navbar.css";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import React, { useContext, useState, useEffect, useCallback } from "react";
import { AuthContext } from "../../context/auth.context";
import { LanguageContext } from "../../context/language.context";
import ReactCountryFlag from "react-country-flag";
import { CartContext } from "../../context/cart.context";

function Navbar() {
  const { isLoggedIn, user, logOutUser } = useContext(AuthContext);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [filter, setFilter] = useState(queryParams.get('filter') || '');
  const [scrolled, setScrolled] = useState(false);

  const { strings, language, setLanguage, countryCode } = useContext(LanguageContext);
  const { cart } = useContext(CartContext);
  const navigate = useNavigate();

  useEffect(() => {
    // Function to handle the window resizing
    const handleResize = () => {
      if (window.innerWidth > 1024 && showMobileMenu) {
        setShowMobileMenu(false);
      }
    };

    // Set the overflow based on the mobile menu state
    document.body.style.overflow = showMobileMenu ? 'hidden' : 'visible';

    // Add the resize event listener
    window.addEventListener('resize', handleResize);

    // Cleanup function to remove the resize event listener and reset overflow
    return () => {
      window.removeEventListener('resize', handleResize);
      if (!showMobileMenu) {
        // Reset the overflow to 'visible' only if the menu is not showing
        document.body.style.overflow = 'visible';
      }
    };
  }, [showMobileMenu, setShowMobileMenu]);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      setScrolled(isScrolled);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleChange = (e) => {
    const language = e.target.value;
    setLanguage(language);
  };

  const renderFlag = (countryCode) => {
    return <ReactCountryFlag countryCode={countryCode} svg />;
  };

  const closeMobileMenu = () => {
    setShowMobileMenu(false);
  };

  const toggleMobileMenu = () => {
    setShowMobileMenu(!showMobileMenu);
  };

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
  };

  const updateUrlParams = (params) => {
    const urlSearchParams = new URLSearchParams(location.search);
    Object.entries(params).forEach(([key, value]) => {
      if (value !== '') {
        urlSearchParams.set(key, value);
      } else {
        urlSearchParams.delete(key);
      }
    });

    const currentPath = location.pathname;
    const newQueryString = urlSearchParams.toString();

    if (currentPath.includes('mycourses')) {
      navigate(`mycourses/?${newQueryString}`, { replace: true });
    } else if (currentPath.includes('courses-admin')) {
      navigate(`courses-admin/?${newQueryString}`, { replace: true });
    } else {
      navigate(`allcourses/?${newQueryString}`, { replace: true });
    }
  };

  const handleSearch = (e) => {
    e.preventDefault(); // Prevent form submission or any default action
    closeMobileMenu();

    const trimmedFilter = filter.trim();

    if (trimmedFilter) {
      // Navigate only if there is a non-empty search query
      updateUrlParams({ filter: trimmedFilter });
    } else {
      // Clear the search if the trimmed filter is empty
      clearSearch();
    }
  };

  const clearSearch = () => {
    updateUrlParams({ filter: '' });
    setFilter('');
  };


  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSearch(e);
      closeMobileMenu()
    }
  };

  const handleLogOut = () => {
    logOutUser();
    closeMobileMenu();
  };

  return (
    <>

      <div className="absolute top-[53px] left-10 md:left-[120px] flex items-center lg:hidden z-[100]">
        <Link to="/" className="flex flex-row items-center w-[128px] sm:w-[158px]">
          <img src="/DionamiteAcademy.png" alt="logo" className={`w-full select-none transition duration-500 ease-out ${showMobileMenu ? "opacity-0" : "opacity-100"}`} />
        </Link>
      </div>

      <nav className={`fixed top-0 pointer-events-none lg:pointer-events-auto z-50 flex justify-center w-full ${scrolled ? "lg:bg-[#02021ccc] py-[53px] lg:py-[20px] lg:backdrop-blur-[3px]" : "py-[53px]"}`}>
        <div className="flex items-center justify-between w-full max-w-[1440px] px-[40px] md:px-[120px] space-x-16">
          <div className="flex items-center">
            <Link to="/" className="hidden lg:flex flex-row items-center w-[128px] sm:w-[158px]">
              <img src="/DionamiteAcademy.png" alt="logo" className={`w-full select-none transition duration-500 ease-out ${showMobileMenu ? "opacity-0" : "opacity-100"}`} />
            </Link>
          </div>

          {/* Other Links and Mobile Menu Button */}
          <div className="flex items-center justify-end w-full sm:space-x-6">
            {/* Desktop Links */}
            <div className="items-center justify-end hidden w-full space-x-8 lg:flex">
              <div className="flex flex-row items-center flex-grow space-x-8">
                <Link to="/allcourses" className="hidden text-xs font-bold tracking-[2px] text-white hover:text-gray-300 md:flex uppercase">
                  {strings.navbar.options.courses.optionName}
                </Link>
                <Link to="/bootcamps" className="hidden text-xs font-bold tracking-[2px] text-white hover:text-gray-300 md:flex uppercase">
                  Bootcamps
                </Link>
                <div className="flex flex-row items-center flex-grow space-x-4">
                  <div className="w-full max-w-[481px] flex-grow flex relative">
                    <input
                      type="text"
                      placeholder="Pesquise na Dionamite Academy"
                      className="w-full px-4 h-10 pr-12 text-xs font-normal tracking-[1px] border border-[#6753B41A] rounded outline-none bg-[#CFC4F814] text-primary placeholder-inherit"
                      value={filter}
                      onChange={handleFilterChange}
                      onKeyDown={handleKeyDown} // Handles search on Enter key
                    />
                    <img
                      src="/searchIcon.png"
                      alt="Search Icon"
                      className="absolute w-4 transform -translate-y-1/2 cursor-pointer right-4 top-1/2"
                      onClick={handleSearch}
                    />
                  </div>
                  {cart?.length > 0 ? <button type="button" className="bg-[#CFC4F814] size-10 rounded flex-shrink-0 border-[#6753B41A] border flex items-center justify-center relative" onClick={() => navigate("/cart")}>
                    <img
                      src="/cartIcon.png"
                      alt="Cart Icon"
                      className="size-6"
                    />
                    <div className="absolute rounded-full h-3 w-3 right-1 bottom-1 text-[9px] text-white bg-primary border border-primary-hover">
                      <div className="-top-[1px] absolute left-[2.6px]">{cart?.length}</div>
                    </div>
                  </button> : ''}

                </div>
              </div>

              {isLoggedIn ? (
                <div className="flex flex-row items-center space-x-8">
                  {user?.role === "user" && <Link to="/mycourses" className="text-xs font-bold tracking-[2px] text-white hover:text-gray-300 uppercase whitespace-nowrap">
                    {strings.navbar.options.courses.myCourses}
                  </Link>}
                  {user?.role === "admin" && (
                    <Link to="/dashboard" className="hidden text-xs font-bold tracking-[2px] text-white hover:text-gray-300 md:flex uppercase">
                      {strings.navbar.options.dashboard}
                    </Link>
                  )}
                  <Link to="/profile" className="text-xs font-bold tracking-[2px] text-white hover:text-gray-300 uppercase">
                    {strings.navbar.options.profile}
                  </Link>
                  <button className="Btn" onClick={logOutUser}>
                    <div className="sign"><svg viewBox="0 0 512 512"><path d="M377.9 105.9L500.7 228.7c7.2 7.2 11.3 17.1 11.3 27.3s-4.1 20.1-11.3 27.3L377.9 406.1c-6.4 6.4-15 9.9-24 9.9c-18.7 0-33.9-15.2-33.9-33.9l0-62.1-128 0c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32l128 0 0-62.1c0-18.7 15.2-33.9 33.9-33.9c9 0 17.6 3.6 24 9.9zM160 96L96 96c-17.7 0-32 14.3-32 32l0 256c0 17.7 14.3 32 32 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-64 0c-53 0-96-43-96-96L0 128C0 75 43 32 96 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32z"></path></svg></div>
                    <div className="text">{strings.navbar.options.logout}</div>
                  </button>
                  {/*  <button onClick={logOutUser} className="text-white hover:text-gray-300">
                  {strings.navbar.options.logout}
                </button> */}
                </div>
              ) : (
                <div className="flex flex-row items-center space-x-6">
                  <Link to="/login" className="text-xs font-bold tracking-[2px] text-white uppercase hover:text-gray-300">
                    {strings.login.login}
                  </Link>
                  <Link to="/signup" className="text-xs font-bold tracking-[2px] text-dionamiteOrange uppercase hover:text-gray-300 border py-3 px-6 border-dionamiteOrange flex-shrink-0">
                    {strings.signup.signUp}
                  </Link>
                </div>
              )}
            </div>
            {/* <button className="hidden ml-auto transition-transform duration-200 ease-linear transform button-language hover:scale-110 md:flex md:flex-wrap">
            <Select
              value={language}
              onChange={handleChange}
              className="whiteArrowSelect"
            >
              <MenuItem
                value={"PT"}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: 18,
                }}
              >
                {countryCode === "BR"
                  ? renderFlag(countryCode)
                  : renderFlag("PT")}
              </MenuItem>
              <MenuItem
                value={"EN"}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: 18,
                }}
              >
                {renderFlag("GB")}
              </MenuItem>
            </Select>
          </button> */}

            {/* Mobile Menu Button */}
            <button onClick={toggleMobileMenu} className="z-50 text-white pointer-events-auto lg:hidden hover:text-gray-300">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-6 h-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="#fff"
              >
                {showMobileMenu ? (
                  // Close icon (X)
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                ) : (
                  // Hamburger icon
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16m-7 6h7" />
                )}
              </svg>
            </button>
          </div>

          {/* Mobile Menu */}
          <div className={`${showMobileMenu ? "show-menu opacity-100 translate-x-0" : "opacity-0 translate-x-full"} fixed inset-y-0 right-0 z-40 w-full bg-[rgba(0,0,0,.42)] transition-opacity duration-500 ease-out transform  flex flex-col lg:hidden overflow-x-clip pointer-events-auto`}>
            <div className="px-[40px] md:px-[120px] pt-[52.5px] sm:pt-[56px] pb-10 backdrop-blur-[3px] bg-[#020213f2] flex flex-col space-y-8">
              {isLoggedIn ? (
                <Link to="/profile" onClick={closeMobileMenu} className="menu-button text-xs font-bold tracking-[2px] text-dionamiteOrange uppercase hover:text-gray-300 border py-3 px-6 border-dionamiteOrange flex-shrink-0 w-fit">
                  {strings.navbar.options.profile}
                </Link>
              ) : (
                <Link to="/signup" onClick={closeMobileMenu} className="menu-button text-xs font-bold tracking-[2px] text-dionamiteOrange uppercase hover:text-gray-300 border py-3 px-6 border-dionamiteOrange flex-shrink-0 w-fit">
                  {strings.signup.signUp}
                </Link>
              )}
              <div className="relative flex flex-grow w-full mb-2 menu-button">
                <input
                  type="text"
                  placeholder="Pesquise na Dionamite Academy"
                  className="w-full px-4 h-12 pr-12 text-xs font-normal tracking-[1px] border border-[#6753B41A] rounded outline-none bg-[#CFC4F814] text-primary placeholder-inherit"
                  value={filter}
                  onChange={handleFilterChange}
                  onKeyDown={handleKeyDown} // Handles search on Enter key
                />
                <img
                  src="/searchIcon.png"
                  alt="Search Icon"
                  className="absolute w-4 transform -translate-y-1/2 cursor-pointer right-4 top-1/2"
                  onClick={handleSearch}
                />
              </div>
              <Link to="/allcourses" className="menu-button text-[14px] font-semibold text-left text-white transition duration-300 uppercase hover:text-dionamiteOrange" onClick={closeMobileMenu}>
                {strings.navbar.options.courses.optionName}
              </Link>
              {cart.length > 0 ? <Link to="/cart" className="menu-button text-[14px] font-semibold text-left text-white transition duration-300 uppercase hover:text-dionamiteOrange" onClick={closeMobileMenu}>
                {strings.cart.title}
              </Link> : null}
              {user?.role === "admin" && (
                <Link to="/dashboard" className="menu-button text-[14px] font-semibold text-left text-white transition duration-300 uppercase hover:text-dionamiteOrange" onClick={closeMobileMenu}>
                  {strings.navbar.options.dashboard}
                </Link>
              )}
              {isLoggedIn ? (
                <>
                  {user?.role === "user" && <Link
                    to="/mycourses"
                    className="menu-button text-[14px] font-semibold text-left text-white transition duration-300 uppercase hover:text-dionamiteOrange"
                    onClick={closeMobileMenu}
                  >
                    {strings.navbar.options.courses.myCourses}
                  </Link>}
                  {/*                   <Link
                    to="/profile"
                    className="text-[14px] font-semibold text-left text-white transition duration-300 uppercase"
                    onClick={closeMobileMenu}
                  >
                    {strings.navbar.options.profile}
                  </Link> */}
                  <button
                    onClick={handleLogOut}
                    className="menu-button px-6 py-3 text-xs font-bold text-left text-red-500 transition duration-300 tracking-[2px] border border-red-500 hover:text-white uppercase w-fit"
                  >
                    {strings.navbar.options.logout}
                  </button>
                </>
              ) : (
                <>
                  {/*                   <Link
                    to="/signup"
                    className="my-2 text-lg font-medium text-left text-white transition duration-300"
                    onClick={closeMobileMenu}
                  >
                    {strings.signup.signUp}
                  </Link> */}
                  <Link
                    to="/login"
                    className="menu-button text-[14px] font-semibold text-left text-white transition duration-300 uppercase hover:text-dionamiteOrange"
                    onClick={closeMobileMenu}
                  >
                    {strings.login.login}
                  </Link>
                </>
              )}
              {/* <button className="flex justify-center w-[62px] self-center mt-4 transition-transform duration-200 ease-linear transform button-language hover:scale-110 md:hidden">
              <Select
                value={language}
                onChange={handleChange}
                className="whiteArrowSelect"
              >
                <MenuItem
                  value={"PT"}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: 18,
                  }}
                >
                  {countryCode === "BR"
                    ? renderFlag(countryCode)
                    : renderFlag("PT")}
                </MenuItem>
                <MenuItem
                  value={"EN"}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: 18,
                  }}
                >
                  {renderFlag("GB")}
                </MenuItem>
              </Select>
            </button> */}
            </div>
          </div>
        </div>
      </nav>

    </>
  );
}

export default Navbar;
