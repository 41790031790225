import React from 'react';
import { Link } from 'react-router-dom';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';

const DashboardPage = () => {
    return (
        <div className='flex mb-20 lg:py-8 containerNavbar'>
            <Breadcrumbs/>
            <div className='flex flex-col'>
                <h1 className='mb-10 text-3xl font-bold text-center text-white sm:text-4xl'>Dashboard</h1>
                <div className='flex flex-wrap justify-center w-full gap-6'>
                    <Link to="/courses-admin" className='flex items-center justify-center text-xs sm:text-sm tracking-[2px] uppercase font-bold text-center text-white transition duration-500 ease-in-out hover:bg-primary-hover hover:text-textHover bg-[#FFFFFF1A] border border-[#A892FF33] rounded-[16px] flex-1 h-[115px] sm:h-[150px] lg:h-[200px] min-w-[150px]'>Cursos</Link>
                    <Link to="/categories-admin" className='flex items-center justify-center text-xs sm:text-sm tracking-[2px] uppercase font-bold text-center text-white transition duration-500 ease-in-out hover:bg-primary-hover hover:text-textHover bg-[#FFFFFF1A] border border-[#A892FF33] rounded-[16px] flex-1 h-[115px] sm:h-[150px] lg:h-[200px] min-w-[150px]'>Categorias</Link>
                    <Link to="/manageAdmins" className='flex items-center justify-center text-xs sm:text-sm tracking-[2px] uppercase font-bold text-center text-white transition duration-500 ease-in-out hover:bg-primary-hover hover:text-textHover bg-[#FFFFFF1A] border border-[#A892FF33] rounded-[16px] flex-1 h-[115px] sm:h-[150px] lg:h-[200px] min-w-[150px]'>Admins</Link>
                    <Link to="/manageTeachers" className='flex items-center justify-center text-xs sm:text-sm tracking-[2px] uppercase font-bold text-center text-white transition duration-500 ease-in-out hover:bg-primary-hover hover:text-textHover bg-[#FFFFFF1A] border border-[#A892FF33] rounded-[16px] flex-1 h-[115px] sm:h-[150px] lg:h-[200px] min-w-[150px]'>Professores</Link>
                    <Link to="/manageCoupons" className='flex items-center justify-center text-xs sm:text-sm tracking-[2px] uppercase font-bold text-center text-white transition duration-500 ease-in-out hover:bg-primary-hover hover:text-textHover bg-[#FFFFFF1A] border border-[#A892FF33] rounded-[16px] flex-1 h-[115px] sm:h-[150px] lg:h-[200px] min-w-[150px]'>Cupões</Link>
                </div>
            </div>
        </div>
    );
};

export default DashboardPage;
