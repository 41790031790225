import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import { AuthProviderWrapper } from "./context/auth.context";
import { LanguageProviderWrapper } from "./context/language.context";
import { GoogleOAuthProvider } from '@react-oauth/google';
import { CartProviderWrapper } from "./context/cart.context";


const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Router>
    <GoogleOAuthProvider clientId="270034436670-50srpfl4e1ianfbk347b8ms9kodae96o.apps.googleusercontent.com">
      <LanguageProviderWrapper>
        <AuthProviderWrapper>
          <CartProviderWrapper>
            <App />
          </CartProviderWrapper>
        </AuthProviderWrapper>
      </LanguageProviderWrapper>
    </GoogleOAuthProvider>
  </Router>
);
