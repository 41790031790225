import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import { AuthContext } from "../../context/auth.context";
import { v4 as uuidv4 } from "uuid";

import Loading from "../../components/Loading/Loading";
import toast from "react-hot-toast";
import { CartContext } from "../../context/cart.context";

// Load your Stripe publishable key
const stripePromise = loadStripe(
    "pk_live_51OMSxBEMfCrRFy2WL1NyIRVd3YrbqU6XaEEpD7v4HVR6mb0RedO0hMrJVY8XGLRRrTjiggMlTV4VsMF2L8rbojaV00LrPzVYd2"
);

const CheckoutPage = () => {
    const { user } = useContext(AuthContext);
    const [finalPrice, setFinalPrice] = useState("");
    const [clientSecret, setClientSecret] = useState("");
    const [refreshKey, setRefreshKey] = useState(Date.now());
    const [currency, setCurrency] = useState("eur");
    const [hideCheckout, setHideCheckout] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const { cart, amount, coupon, checkedWordpress } = useContext(CartContext);

    const checkPaymentIntentStatus = async (paymentIntentId) => {
        try {
            const response = await axios.patch(
                `${process.env.REACT_APP_SERVER_URL}/stripe/verify-and-purchase`,
                {
                    paymentIntentId,
                    userId: user?._id,
                },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
                    },
                }
            );

            if (response.data.success === true) {
                setHideCheckout(true);
                /*  navigate(`/course/${courseId}`); */
                toast.success("Curso comprado com successo!");
            }
        } catch (error) {
            console.error("Error checking payment intent status:", error);
            toast.error(error.response.data.message);
        }
    };

    useEffect(() => {
        const fetchCourseAndCreateIntent = async () => {
            try {
                const hasPurchased = cart.some((course) =>
                    course.purchased?.some((purchase) => purchase.userId === user?._id)
                );

                if (hasPurchased) {
                    navigate(`/cart`);
                    toast.error("Já compraste um dos cursos no teu carrinho.")
                    return;
                }

                setFinalPrice(amount);

                if (cart && amount) {
                    const idempotencyKey = uuidv4();
                    const paymentIntentData = {
                        amount: amount,
                        coursesId: cart.map(course => course._id),
                        userId: user?._id,
                        idempotencyKey,
                        checkedWordpress
                    };

                    // console.log(paymentIntentData)

                    if (coupon) {
                        paymentIntentData.coupon = coupon;
                    }

                    const paymentIntentResponse = await axios.post(
                        `${process.env.REACT_APP_SERVER_URL}/stripe/create-payment-intent`,
                        paymentIntentData,
                        {
                            headers: {
                                Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
                            },
                        }
                    );
                    setClientSecret(paymentIntentResponse.data.clientSecret);
                }
            } catch (error) {
                console.error("Error setting up payment:", error);
            } finally {
                setIsLoading(false);
            }
        };
        fetchCourseAndCreateIntent();

        // Check payment intent status if paymentIntent is in URL
        const queryParams = new URLSearchParams(location.search);
        const paymentIntent = queryParams.get("payment_intent");
        if (paymentIntent) {
            checkPaymentIntentStatus(paymentIntent);
        }
    }, [user?._id, amount, currency, location.search, cart]);


    const appearance = {
        theme: "night",
        labels: "floating",
        variables: {
            colorPrimary: "#6753B4",
            colorBackground: "#1a173a",
            colorDanger: "#df1b41",
            fontFamily: "Inter, Ideal Sans, system-ui, sans-serif",
            borderRadius: "4px",
        },
    };

    if (isLoading) {
        return <Loading />;
    }

    return (
        <>
            {cart && clientSecret ? (
                <div className="mb-20 lg:py-8 containerNavbar">
                    <div>
                        <div className="w-full">
                            <h1 className="mb-6 text-2xl font-bold text-white sm:text-4xl">
                                Checkout
                            </h1>
                            <>
                                {hideCheckout &&
                                    <Elements
                                        key={refreshKey}
                                        stripe={stripePromise}
                                        options={{ clientSecret, appearance }}
                                    >
                                        <CheckoutForm
                                            amount={finalPrice}
                                            currency={currency}
                                            userId={user._id}
                                            user={user}
                                        />
                                    </Elements>
                                }
                            </>
                        </div>
                    </div>
                </div>
            ) : (
                <Loading />
            )}
        </>
    );
};

export default CheckoutPage;
