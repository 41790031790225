import { Link, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";

function Breadcrumbs({ courseId, courseName, lessonName, moduleName, moduleId }) {
    const location = useLocation();
    const locationUrl = location.pathname;
    const locations = locationUrl.split('/').filter(loc => loc); // Remove empty strings

    const customNames = {
        'courses-admin': 'Cursos',
        'categories-admin': 'Categorias',
        'manageAdmins': 'Admins',
        'manageTeachers': 'Professores',
        'manageCoupons': 'Cupões',
        'lesson-admin': 'Lições',
        'dashboard': 'Dashboard',
        'home': 'Home',
    };

    const getCrumbName = (crumb) => {
        if (crumb === courseId) {
            return courseName || customNames[crumb] || capitalize(crumb);
        }
        return customNames[crumb] || capitalize(crumb);
    };

    const capitalize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };

    const breadCrumbs = () => {
        const crumbs = [];
        let accumulatedPath = '';

        // Always add Home
        crumbs.push(
            <span key="home">
                <Link to="/">Home</Link>
            </span>
        );

        // Add separator
        crumbs.push(
            <span key="separator-home"> {' > '} </span>
        );

        // Always add Dashboard
        crumbs.push(
            <span key="dashboard">
                <Link to="/dashboard">Dashboard</Link>
            </span>
        );

        // Determine the starting index
        const startIndex = locations.includes('dashboard') ? locations.indexOf('dashboard') + 1 : 0;

        // Flag to indicate the next segment is a lessonId
        let isLessonId = false;

        // Iterate through the remaining path segments
        for (let i = startIndex; i < locations.length; i++) {
            const crumb = locations[i];
            if (crumb === 'lesson-admin') {
                // Add 'Lições' and related links
                accumulatedPath += `/${crumb}`;
                crumbs.push(
                    <span key={`crumb-${i}`}>
                        {' > '}
                        <Link to="/courses-admin">Cursos</Link>
                        {' > '}
                        <Link to={`/courses-admin/${courseId}`}>{courseName}</Link>
                        {' > '}
                        <Link to={`/courses-admin/${courseId}?view=modules`}>Módulos</Link>
                        {' > '}
                        <Link to={`/courses-admin/${courseId}?view=modules&module=${moduleId}`}>{moduleName}</Link>
                    </span>
                );
                isLessonId = true;
                continue;
            }
            if (isLessonId) {
                // The current crumb is a lessonId, use lessonName
                accumulatedPath += `/${crumb}`;
                crumbs.push(
                    <span key={`lesson-${i}`}>
                        {' > '}
                        {lessonName}
                    </span>
                );
                isLessonId = false;
                continue;
            }

            // Handle other segments
            accumulatedPath += `/${crumb}`;
            const isLast = i === locations.length - 1;

            if (isLast) {
                // Last crumb: render as plain text
                const displayName = crumb === courseId ? (courseName || getCrumbName(crumb)) : getCrumbName(crumb);
                crumbs.push(
                    <span key={`crumb-${i}`}>
                        {' > '}
                        {displayName}
                    </span>
                );
            } else {
                // Render as link
                crumbs.push(
                    <span key={`crumb-${i}`}>
                        {' > '}
                        <Link to={accumulatedPath}>{getCrumbName(crumb)}</Link>
                    </span>
                );
            }
        }

        return crumbs;
    };

    return (
        <nav aria-label="Breadcrumb">
            <div className={`text-white p-4`}>
                {breadCrumbs ? breadCrumbs() : <span className="text-transparent">breadCrumbs</span>}
            </div>
        </nav>
    );
}

export default Breadcrumbs;
