import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { AuthContext } from "../../context/auth.context";
import { LanguageContext } from "../../context/language.context";
import Loading from "../../components/Loading/Loading";
import { CartContext } from "../../context/cart.context";
import { FaInfoCircle, FaTrash } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import toast from "react-hot-toast";
import axios from "axios";
import { MdArrowOutward } from 'react-icons/md';

const CartPage = () => {
    const { user } = useContext(AuthContext);
    const { strings } = useContext(LanguageContext);
    const [isLoading, setIsLoading] = useState(false);
    const [validCoupon, setValidCoupon] = useState(null);
    const navigate = useNavigate();
    const {
        cart,
        handleRemoveItemCart,
        coupon,
        setCoupon,
        couponInfo,
        setCouponInfo,
        couponApplied,
        setCouponApplied,
        amount,
        setAmount,
        wordpressSelections,
        setWordpressSelections,
        setCheckedWordpress,
        handleCartClearAndRefresh
    } = useContext(CartContext);

    // Function to check if course is included in specific coupon
    const isCourseApplicable = (couponCourses) => {
        // Check if at least one course in the cart is included in the coupon's applicable courses
        return cart?.some((cartCourse) =>
            couponCourses?.some(
                (couponCourse) =>
                    couponCourse._id?.toString() === cartCourse._id?.toString()
            )
        );
    };

    const handleCoupon = async () => {
        if (cart.length <= 0) {
            toast.error("Não tem nada no carrinho.");
            return;
        }
        try {
            const res = await axios.get(
                `${process.env.REACT_APP_SERVER_URL}/coupon/getCoupon/${coupon}`,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
                    },
                }
            );

            setCouponInfo(res.data);
            const couponData = res.data;
            let checkCoupon = true;

            if (couponData) {
                // Check expiration and other conditions
                const now = new Date(); // Get current date
                const couponExpiration = new Date(couponData.expiration); // Convert expiration string to date object
                const userId = user?._id; // Assuming `user` is defined and contains the current user's data

                // Check if the coupon is expired or invalid
                if (
                    couponExpiration > now &&
                    (couponData.percentage != null || couponData.price != null)
                ) {
                    // Check if the coupon is one-time use and if the user has already used it
                    if (
                        couponData.usageLimit === "one-time" &&
                        couponData.usedBy.includes(userId)
                    ) {
                        setValidCoupon(false);
                        setCouponInfo(null); // Clear the coupon info
                        setCouponApplied(false); // Ensure couponApplied is also false
                        toast.error("Você já usou este cupão");
                        return;
                    }

                    // Check coupon type and course applicability (existing logic)
                    if (
                        couponData.availability === "general" ||
                        (couponData.availability === "specific" &&
                            isCourseApplicable(couponData.courses))
                    ) {
                        setValidCoupon(true);
                        setCouponApplied(true);
                        toast.success("Cupão aplicado com sucesso");
                    } else {
                        setValidCoupon(false);
                        checkCoupon = false;
                        toast.error("Cupão não aplicável a este curso");
                    }
                } else {
                    setValidCoupon(false);
                    toast.error("Cupão inválido ou expirado");
                    checkCoupon = false;
                }
            } else {
                setValidCoupon(false);
                checkCoupon = false;
                toast.error("Cupão inválido ou expirado");
            }
        } catch (error) {
            console.error("Error fetching coupon details:", error);
            toast.error("Erro ao buscar detalhes do cupão");
        }
    };

    const handleRemoveCoupon = () => {
        setCouponApplied(false);
        setCoupon("");
        setCouponInfo("");
    };

    // When WordPress selections change, recalculate the total
    useEffect(() => {
        const total = cart.reduce((acc, course) => {
            const { discounted } = getDiscountedPrice(course);
            return acc + parseFloat(discounted);
        }, 0);
        setAmount(total.toFixed(2));
    }, [cart, couponInfo, wordpressSelections, setAmount]);


    if (isLoading) {
        return <Loading />;
    }

    // Calculates the discounted price for a course.
    // Adds an extra €10 if the course is a WordPress course and the checkbox is checked.
    const getDiscountedPrice = (course) => {
        // Calculate the original price (base price + WordPress addition if applicable)
        let originalPrice = course.price;
        if (wordpressSelections[course._id]) {
            originalPrice += 10;
        }

        // Start with the base price to compute the discounted price
        let discountedPrice = course.price;

        // Apply coupon discount if applicable
        if (couponInfo && couponApplied) {
            if (couponInfo.availability === "general") {
                if (couponInfo.type === "percentage") {
                    discountedPrice = course.price * (1 - couponInfo.percentage / 100);
                } else if (couponInfo.type === "price") {
                    discountedPrice = couponInfo.price;
                }
            } else if (couponInfo.availability === "specific") {
                const applicable = couponInfo.courses.some(
                    (couponCourse) =>
                        couponCourse?._id.toString() === course._id?.toString()
                );
                if (applicable) {
                    if (couponInfo.type === "percentage") {
                        discountedPrice = course.price * (1 - couponInfo.percentage / 100);
                    } else if (couponInfo.type === "price") {
                        discountedPrice = couponInfo.price;
                    }
                }
            }
        }

        // Apply the WordPress additional fee to the discounted price as well
        if (wordpressSelections[course._id]) {
            discountedPrice += 10;
        }

        // Return both prices as formatted strings (or as numbers if you prefer)
        return {
            original: originalPrice.toFixed(2),
            discounted: discountedPrice.toFixed(2)
        };
    };


    // Toggles the WordPress checkbox state for the course.
    // If toggled, it will add or remove €10 from that course's price.
    const handleWordPressCheck = (course) => {
        setWordpressSelections((prev) => {
            const newVal = !prev[course._id];
            const newSelections = { ...prev, [course._id]: newVal };
            // If any course is selected, checkedWordpress should be true
            const anyChecked = Object.values(newSelections).some((value) => value);
            setCheckedWordpress(anyChecked);
            return newSelections;
        });
    };

    const getFirstParagraph = (text) => {
        if (!text) return "";
        const paragraphs = text.split("\n");
        return paragraphs[0] || text;
    };

    // Compute whether any WordPress course is selected.
    const anyWordPressChecked = Object.values(wordpressSelections).some(
        (value) => value
    );

    const handleBuyButtonWithCoupon = async () => {
        if (couponInfo.price === 0 && cart.length === 1) {
            const coursesIds = cart.map((course) => course._id);
            const response = await axios.patch(
                `${process.env.REACT_APP_SERVER_URL}/course/paywithcoupon`,
                {
                    coursesIds,
                    userId: user?._id,
                    couponName: couponInfo.name,
                },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            "accessToken"
                        )}`,
                    },
                }
            );
            toast.success("Curso comprado com sucesso");

            navigate(`/course/${cart[0].slug}`);
            handleCartClearAndRefresh();
        }

        if (couponInfo.price === 0 && cart.length >= 2) {
            // Create an array of all course IDs in the cart.
            const coursesIds = cart.map((course) => course._id);
            try {
                const response = await axios.patch(
                    `${process.env.REACT_APP_SERVER_URL}/course/paywithcoupon`,
                    {
                        coursesIds, // Sending an array of course IDs
                        userId: user?._id,
                        couponName: couponInfo.name,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
                        },
                    }
                );
                toast.success("Cursos comprados com sucesso");

                // You might want to navigate to a "My Courses" or similar page after purchase
                navigate("/mycourses");
                handleCartClearAndRefresh();
            } catch (error) {
                console.error("Error purchasing courses with coupon:", error);
                toast.error("Erro ao comprar cursos com cupão");
            }
        }
    };

    return (
        <>
            {cart ? (
                <div className="mb-20 lg:py-8 containerNavbar text-white">
                    <div className="lg:mx-32">
                        <div className="w-full">
                            <h1 className="mb-6 text-2xl font-bold text-white sm:text-4xl">
                                {strings.cart.title}
                            </h1>
                            {cart.length > 0 ? <>
                                {cart.map((item, index) => {
                                    const prices = getDiscountedPrice(item);
                                    // Check if this course belongs to the "wordpress" category
                                    const isWordPressCourse = item?.categories?.some(
                                        (category) =>
                                            category?.name?.toLowerCase() === "wordpress"
                                    );
                                    return (
                                        <div
                                            key={index}
                                            className="w-full bg-[#FFFFFF1A] border border-[#A892FF33] text-white rounded-lg flex flex-row justify-between p-4 mb-3 cartcard"
                                        >
                                            <div className="flex flex-row">
                                                <img
                                                    src={item?.image}
                                                    alt={item.title}
                                                    className="rounded h-20 w-20 object-cover self-center"
                                                />
                                                <div className="px-4 py-2">
                                                    <p className="text-1xl font-bold text-white uppercase">
                                                        {item.title}
                                                    </p>
                                                    <p className="text-xs text-white text-opacity-70">
                                                        {getFirstParagraph(item.description)}
                                                    </p>
                                                    {/* Render WordPress checkbox only for WordPress courses */}
                                                    {isWordPressCourse &&
                                                        (
                                                            // If any WordPress course is checked, show checkbox only for that course.
                                                            // Otherwise, show the checkbox for all WordPress courses.
                                                            (!anyWordPressChecked || wordpressSelections[item._id]) && (
                                                                <div className="flex items-center pt-1">
                                                                    <input
                                                                        id={`wordpress-checkbox-${item._id}`}
                                                                        type="checkbox"
                                                                        className="w-3 h-3 accent-primary"
                                                                        checked={!!wordpressSelections[item._id]}
                                                                        onChange={() => handleWordPressCheck(item)}
                                                                    />
                                                                    <div className="ms-2 text-sm text-white flex flex-row items-center">
                                                                        {strings.purchase.wordpressCheck} (+10€)
                                                                        <div className="has-tooltip relative">
                                                                            <FaInfoCircle className="w-4 h-4 ml-2" />
                                                                            <span className="tooltip rounded shadow-lg p-2 bottom-0 left-4 mb-5 bg-primary border border-primary-hover text-white w-72">
                                                                                {strings.purchase.wordpressTooltip}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        )}
                                                </div>
                                            </div>
                                            <div className="flex flex-row items-center">
                                                {prices.original !== prices.discounted ? (
                                                    <p className="px-4 py-2">
                                                        <span className="line-through pr-2">€{prices.original}</span>
                                                        <span>€{prices.discounted}</span>
                                                    </p>
                                                ) : (
                                                    <p className="px-4 py-2">€{prices.original}</p>
                                                )}
                                                <button
                                                    className=" rounded transition duration-500 ease-in-out hover:bg-dionamiteOrange bg-[#fd8a48] text-[12px] sm:text-[13px] uppercase font-bold p-3 text-[#381708] tracking-[2px]"
                                                    onClick={() => handleRemoveItemCart(item)}
                                                >
                                                    <FaTrash />
                                                </button>
                                            </div>
                                        </div>
                                    );
                                })}
                                <div>
                                    <div className="p-4 flex flex-col">
                                        <p className="uppercase font-bold text-end">
                                            Total: €{amount}
                                        </p>
                                        <div className="flex my-4 space-x-2 justify-end">
                                            {couponApplied && (
                                                <button
                                                    className="sm:flex-shrink-0 hover:bg-primary-hover rounded transition duration-500 ease-in-out bg-primary text-[11px] sm:text-[12px] uppercase font-bold p-3 text-white hover:text-textHover tracking-[2px]"
                                                    onClick={handleRemoveCoupon}
                                                >
                                                    <IoClose className="w-4 h-4" />
                                                </button>
                                            )}
                                            <input
                                                type="text"
                                                value={coupon}
                                                onChange={(e) => setCoupon(e.target.value)}
                                                placeholder={strings.purchase.couponPlaceholder}
                                                className="pl-4 text-xs font-normal tracking-[1px] border border-[#6753B41A] rounded outline-none bg-[#CFC4F814] text-secondary placeholder-inherit"
                                                disabled={couponApplied}
                                                maxLength={30}
                                            />
                                            <button
                                                onClick={coupon ? handleCoupon : null}
                                                disabled={couponApplied}
                                                className="sm:flex-shrink-0 hover:bg-primary-hover rounded transition duration-500 ease-in-out bg-primary text-[11px] sm:text-[12px] uppercase font-bold py-3 px-2 sm:px-6 text-white hover:text-textHover tracking-[2px]"
                                            >
                                                {strings.purchase.applyCoupon}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                {couponInfo.price === 0 && amount < 1 ?
                                    <button
                                        className="w-full hover:bg-primary-hover rounded transition duration-500 ease-in-out bg-primary text-[12px] sm:text-[13px] uppercase font-bold py-3 px-6 text-white hover:text-textHover tracking-[2px]"
                                        onClick={() => handleBuyButtonWithCoupon()}
                                    >
                                        {strings.cart.finalize}
                                    </button> : <div>
                                        {cart.length > 0 && amount > 0 ?
                                            <button
                                                className="w-full hover:bg-primary-hover rounded transition duration-500 ease-in-out bg-primary text-[12px] sm:text-[13px] uppercase font-bold py-3 px-6 text-white hover:text-textHover tracking-[2px]"
                                                onClick={() => navigate("/checkout")}
                                            >
                                                {strings.cart.finalize}
                                            </button> :
                                            <button
                                                className="w-full hover:bg-primary-hover rounded transition duration-500 ease-in-out bg-primary text-[12px] sm:text-[13px] uppercase font-bold py-3 px-6 text-white hover:text-textHover tracking-[2px]" disabled
                                            >
                                                {strings.cart.emptycart}
                                            </button>}
                                    </div>}
                            </> :
                                <div className="flex flex-col items-center">
                                    <p className="mb-[10px] font-bold text-[32px] leading-[40px] gradient-hero">{strings.cart.nothingincart}</p>
                                    <Link to={"/"} className="mt-5 items-center gap-1 hover:text-orange text-veryLightOrange flex justify-center">
                                        <MdArrowOutward className="-rotate-90 text-white" />
                                        <span className="font-bold uppercase text-white gradient-hero">{strings.cart.letsgoback}</span>
                                    </Link>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            ) : (
                <Loading />
            )}
        </>
    );
};

export default CartPage;
